exports.components = {
  "component---src-templates-articles-ai-architecture-ai-architecture-tsx": () => import("./../../../src/templates/articles/ai-architecture/ai-architecture.tsx" /* webpackChunkName: "component---src-templates-articles-ai-architecture-ai-architecture-tsx" */),
  "component---src-templates-articles-ai-automotive-ai-automotive-tsx": () => import("./../../../src/templates/articles/ai-automotive/ai-automotive.tsx" /* webpackChunkName: "component---src-templates-articles-ai-automotive-ai-automotive-tsx" */),
  "component---src-templates-articles-ai-chatgpt-use-cases-ai-chatgpt-use-cases-tsx": () => import("./../../../src/templates/articles/ai-chatgpt-use-cases/ai-chatgpt-use-cases.tsx" /* webpackChunkName: "component---src-templates-articles-ai-chatgpt-use-cases-ai-chatgpt-use-cases-tsx" */),
  "component---src-templates-articles-ai-cybersecurity-ai-cybersecurity-tsx": () => import("./../../../src/templates/articles/ai-cybersecurity/ai-cybersecurity.tsx" /* webpackChunkName: "component---src-templates-articles-ai-cybersecurity-ai-cybersecurity-tsx" */),
  "component---src-templates-articles-ai-education-ai-education-tsx": () => import("./../../../src/templates/articles/ai-education/ai-education.tsx" /* webpackChunkName: "component---src-templates-articles-ai-education-ai-education-tsx" */),
  "component---src-templates-articles-ai-fintech-ai-fintech-tsx": () => import("./../../../src/templates/articles/ai-fintech/ai-fintech.tsx" /* webpackChunkName: "component---src-templates-articles-ai-fintech-ai-fintech-tsx" */),
  "component---src-templates-articles-ai-hr-ai-hr-tsx": () => import("./../../../src/templates/articles/ai-hr/ai-hr.tsx" /* webpackChunkName: "component---src-templates-articles-ai-hr-ai-hr-tsx" */),
  "component---src-templates-articles-ai-in-crm-ai-in-crm-tsx": () => import("./../../../src/templates/articles/ai-in-crm/ai-in-crm.tsx" /* webpackChunkName: "component---src-templates-articles-ai-in-crm-ai-in-crm-tsx" */),
  "component---src-templates-articles-ai-in-radiology-ai-in-radiology-tsx": () => import("./../../../src/templates/articles/ai-in-radiology/ai-in-radiology.tsx" /* webpackChunkName: "component---src-templates-articles-ai-in-radiology-ai-in-radiology-tsx" */),
  "component---src-templates-articles-ai-social-ai-social-tsx": () => import("./../../../src/templates/articles/ai-social/ai-social.tsx" /* webpackChunkName: "component---src-templates-articles-ai-social-ai-social-tsx" */),
  "component---src-templates-articles-ai-sports-ai-sports-tsx": () => import("./../../../src/templates/articles/ai-sports/ai-sports.tsx" /* webpackChunkName: "component---src-templates-articles-ai-sports-ai-sports-tsx" */),
  "component---src-templates-articles-ai-transportation-ai-transportation-tsx": () => import("./../../../src/templates/articles/ai-transportation/ai-transportation.tsx" /* webpackChunkName: "component---src-templates-articles-ai-transportation-ai-transportation-tsx" */),
  "component---src-templates-articles-ai-wealth-management-ai-wealth-management-tsx": () => import("./../../../src/templates/articles/ai-wealth-management/ai-wealth-management.tsx" /* webpackChunkName: "component---src-templates-articles-ai-wealth-management-ai-wealth-management-tsx" */),
  "component---src-templates-articles-ai-workplace-ai-workplace-tsx": () => import("./../../../src/templates/articles/ai-workplace/ai-workplace.tsx" /* webpackChunkName: "component---src-templates-articles-ai-workplace-ai-workplace-tsx" */),
  "component---src-templates-articles-ar-education-ar-education-tsx": () => import("./../../../src/templates/articles/ar-education/ar-education.tsx" /* webpackChunkName: "component---src-templates-articles-ar-education-ar-education-tsx" */),
  "component---src-templates-articles-automotive-iot-automotive-iot-tsx": () => import("./../../../src/templates/articles/automotive-iot/automotive-iot.tsx" /* webpackChunkName: "component---src-templates-articles-automotive-iot-automotive-iot-tsx" */),
  "component---src-templates-articles-aws-for-devops-aws-for-devops-tsx": () => import("./../../../src/templates/articles/aws-for-devops/aws-for-devops.tsx" /* webpackChunkName: "component---src-templates-articles-aws-for-devops-aws-for-devops-tsx" */),
  "component---src-templates-articles-aws-migration-aws-migration-tsx": () => import("./../../../src/templates/articles/aws-migration/aws-migration.tsx" /* webpackChunkName: "component---src-templates-articles-aws-migration-aws-migration-tsx" */),
  "component---src-templates-articles-aws-migration-strategies-aws-migration-strategies-tsx": () => import("./../../../src/templates/articles/aws-migration-strategies/aws-migration-strategies.tsx" /* webpackChunkName: "component---src-templates-articles-aws-migration-strategies-aws-migration-strategies-tsx" */),
  "component---src-templates-articles-bi-enterprise-bi-enterprise-tsx": () => import("./../../../src/templates/articles/bi-enterprise/bi-enterprise.tsx" /* webpackChunkName: "component---src-templates-articles-bi-enterprise-bi-enterprise-tsx" */),
  "component---src-templates-articles-bi-for-finance-bi-for-finance-tsx": () => import("./../../../src/templates/articles/bi-for-finance/bi-for-finance.tsx" /* webpackChunkName: "component---src-templates-articles-bi-for-finance-bi-for-finance-tsx" */),
  "component---src-templates-articles-bi-for-healthcare-bi-for-healthcare-tsx": () => import("./../../../src/templates/articles/bi-for-healthcare/bi-for-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-bi-for-healthcare-bi-for-healthcare-tsx" */),
  "component---src-templates-articles-bi-for-marketing-bi-for-marketing-tsx": () => import("./../../../src/templates/articles/bi-for-marketing/bi-for-marketing.tsx" /* webpackChunkName: "component---src-templates-articles-bi-for-marketing-bi-for-marketing-tsx" */),
  "component---src-templates-articles-bi-for-retail-bi-for-retail-tsx": () => import("./../../../src/templates/articles/bi-for-retail/bi-for-retail.tsx" /* webpackChunkName: "component---src-templates-articles-bi-for-retail-bi-for-retail-tsx" */),
  "component---src-templates-articles-bi-manufacturing-bi-manufacturing-tsx": () => import("./../../../src/templates/articles/bi-manufacturing/bi-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-bi-manufacturing-bi-manufacturing-tsx" */),
  "component---src-templates-articles-bi-mobile-bi-mobile-tsx": () => import("./../../../src/templates/articles/bi-mobile/bi-mobile.tsx" /* webpackChunkName: "component---src-templates-articles-bi-mobile-bi-mobile-tsx" */),
  "component---src-templates-articles-bi-self-service-bi-self-service-tsx": () => import("./../../../src/templates/articles/bi-self-service/bi-self-service.tsx" /* webpackChunkName: "component---src-templates-articles-bi-self-service-bi-self-service-tsx" */),
  "component---src-templates-articles-blockchain-healthcare-blockchain-healthcare-tsx": () => import("./../../../src/templates/articles/blockchain-healthcare/blockchain-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-blockchain-healthcare-blockchain-healthcare-tsx" */),
  "component---src-templates-articles-blockchain-insurance-blockchain-insurance-tsx": () => import("./../../../src/templates/articles/blockchain-insurance/blockchain-insurance.tsx" /* webpackChunkName: "component---src-templates-articles-blockchain-insurance-blockchain-insurance-tsx" */),
  "component---src-templates-articles-bpm-healthcare-bpm-healthcare-tsx": () => import("./../../../src/templates/articles/bpm-healthcare/bpm-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-bpm-healthcare-bpm-healthcare-tsx" */),
  "component---src-templates-articles-cloud-based-erp-cloud-based-erp-tsx": () => import("./../../../src/templates/articles/cloud-based-erp/cloud-based-erp.tsx" /* webpackChunkName: "component---src-templates-articles-cloud-based-erp-cloud-based-erp-tsx" */),
  "component---src-templates-articles-computer-vision-algorithm-computer-vision-algorithm-tsx": () => import("./../../../src/templates/articles/computer-vision-algorithm/computer-vision-algorithm.tsx" /* webpackChunkName: "component---src-templates-articles-computer-vision-algorithm-computer-vision-algorithm-tsx" */),
  "component---src-templates-articles-computer-vision-healthcare-computer-vision-healthcare-tsx": () => import("./../../../src/templates/articles/computer-vision-healthcare/computer-vision-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-computer-vision-healthcare-computer-vision-healthcare-tsx" */),
  "component---src-templates-articles-computer-vision-manufacturing-computer-vision-manufacturing-tsx": () => import("./../../../src/templates/articles/computer-vision-manufacturing/computer-vision-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-computer-vision-manufacturing-computer-vision-manufacturing-tsx" */),
  "component---src-templates-articles-crm-automotive-crm-automotive-tsx": () => import("./../../../src/templates/articles/crm-automotive/crm-automotive.tsx" /* webpackChunkName: "component---src-templates-articles-crm-automotive-crm-automotive-tsx" */),
  "component---src-templates-articles-crm-custom-crm-custom-tsx": () => import("./../../../src/templates/articles/crm-custom/crm-custom.tsx" /* webpackChunkName: "component---src-templates-articles-crm-custom-crm-custom-tsx" */),
  "component---src-templates-articles-crm-enterprise-crm-enterprise-tsx": () => import("./../../../src/templates/articles/crm-enterprise/crm-enterprise.tsx" /* webpackChunkName: "component---src-templates-articles-crm-enterprise-crm-enterprise-tsx" */),
  "component---src-templates-articles-crm-finance-crm-finance-tsx": () => import("./../../../src/templates/articles/crm-finance/crm-finance.tsx" /* webpackChunkName: "component---src-templates-articles-crm-finance-crm-finance-tsx" */),
  "component---src-templates-articles-crm-hosted-crm-hosted-tsx": () => import("./../../../src/templates/articles/crm-hosted/crm-hosted.tsx" /* webpackChunkName: "component---src-templates-articles-crm-hosted-crm-hosted-tsx" */),
  "component---src-templates-articles-crm-insurance-crm-insurance-tsx": () => import("./../../../src/templates/articles/crm-insurance/crm-insurance.tsx" /* webpackChunkName: "component---src-templates-articles-crm-insurance-crm-insurance-tsx" */),
  "component---src-templates-articles-crm-manufacturing-crm-manufacturing-tsx": () => import("./../../../src/templates/articles/crm-manufacturing/crm-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-crm-manufacturing-crm-manufacturing-tsx" */),
  "component---src-templates-articles-crm-real-estate-crm-real-estate-tsx": () => import("./../../../src/templates/articles/crm-real-estate/crm-real-estate.tsx" /* webpackChunkName: "component---src-templates-articles-crm-real-estate-crm-real-estate-tsx" */),
  "component---src-templates-articles-crm-salesforce-construction-crm-salesforce-construction-tsx": () => import("./../../../src/templates/articles/crm-salesforce-construction/crm-salesforce-construction.tsx" /* webpackChunkName: "component---src-templates-articles-crm-salesforce-construction-crm-salesforce-construction-tsx" */),
  "component---src-templates-articles-crm-salesforce-education-crm-salesforce-education-tsx": () => import("./../../../src/templates/articles/crm-salesforce-education/crm-salesforce-education.tsx" /* webpackChunkName: "component---src-templates-articles-crm-salesforce-education-crm-salesforce-education-tsx" */),
  "component---src-templates-articles-crm-salesforce-finance-crm-salesforce-finance-tsx": () => import("./../../../src/templates/articles/crm-salesforce-finance/crm-salesforce-finance.tsx" /* webpackChunkName: "component---src-templates-articles-crm-salesforce-finance-crm-salesforce-finance-tsx" */),
  "component---src-templates-articles-crm-salesforce-healthcare-crm-salesforce-healthcare-tsx": () => import("./../../../src/templates/articles/crm-salesforce-healthcare/crm-salesforce-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-crm-salesforce-healthcare-crm-salesforce-healthcare-tsx" */),
  "component---src-templates-articles-crm-salesforce-manufacturing-crm-salesforce-manufacturing-tsx": () => import("./../../../src/templates/articles/crm-salesforce-manufacturing/crm-salesforce-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-crm-salesforce-manufacturing-crm-salesforce-manufacturing-tsx" */),
  "component---src-templates-articles-crm-salesforce-microsoft-crm-salesforce-microsoft-tsx": () => import("./../../../src/templates/articles/crm-salesforce-microsoft/crm-salesforce-microsoft.tsx" /* webpackChunkName: "component---src-templates-articles-crm-salesforce-microsoft-crm-salesforce-microsoft-tsx" */),
  "component---src-templates-articles-crm-salesforce-retail-crm-salesforce-retail-tsx": () => import("./../../../src/templates/articles/crm-salesforce-retail/crm-salesforce-retail.tsx" /* webpackChunkName: "component---src-templates-articles-crm-salesforce-retail-crm-salesforce-retail-tsx" */),
  "component---src-templates-articles-crm-strategy-crm-strategy-tsx": () => import("./../../../src/templates/articles/crm-strategy/crm-strategy.tsx" /* webpackChunkName: "component---src-templates-articles-crm-strategy-crm-strategy-tsx" */),
  "component---src-templates-articles-custom-ehr-custom-ehr-tsx": () => import("./../../../src/templates/articles/custom-ehr/custom-ehr.tsx" /* webpackChunkName: "component---src-templates-articles-custom-ehr-custom-ehr-tsx" */),
  "component---src-templates-articles-data-warehouse-modernization-data-warehouse-modernization-tsx": () => import("./../../../src/templates/articles/data-warehouse-modernization/data-warehouse-modernization.tsx" /* webpackChunkName: "component---src-templates-articles-data-warehouse-modernization-data-warehouse-modernization-tsx" */),
  "component---src-templates-articles-dental-ehr-dental-ehr-tsx": () => import("./../../../src/templates/articles/dental-ehr/dental-ehr.tsx" /* webpackChunkName: "component---src-templates-articles-dental-ehr-dental-ehr-tsx" */),
  "component---src-templates-articles-ecommerce-personalization-ecommerce-personalization-tsx": () => import("./../../../src/templates/articles/ecommerce-personalization/ecommerce-personalization.tsx" /* webpackChunkName: "component---src-templates-articles-ecommerce-personalization-ecommerce-personalization-tsx" */),
  "component---src-templates-articles-ecommerce-security-ecommerce-security-tsx": () => import("./../../../src/templates/articles/ecommerce-security/ecommerce-security.tsx" /* webpackChunkName: "component---src-templates-articles-ecommerce-security-ecommerce-security-tsx" */),
  "component---src-templates-articles-ecommerce-virtual-store-ecommerce-virtual-store-tsx": () => import("./../../../src/templates/articles/ecommerce-virtual-store/ecommerce-virtual-store.tsx" /* webpackChunkName: "component---src-templates-articles-ecommerce-virtual-store-ecommerce-virtual-store-tsx" */),
  "component---src-templates-articles-ehr-cardiology-ehr-cardiology-tsx": () => import("./../../../src/templates/articles/ehr-cardiology/ehr-cardiology.tsx" /* webpackChunkName: "component---src-templates-articles-ehr-cardiology-ehr-cardiology-tsx" */),
  "component---src-templates-articles-ehr-cloud-based-ehr-cloud-based-tsx": () => import("./../../../src/templates/articles/ehr-cloud-based/ehr-cloud-based.tsx" /* webpackChunkName: "component---src-templates-articles-ehr-cloud-based-ehr-cloud-based-tsx" */),
  "component---src-templates-articles-ehr-costs-ehr-costs-tsx": () => import("./../../../src/templates/articles/ehr-costs/ehr-costs.tsx" /* webpackChunkName: "component---src-templates-articles-ehr-costs-ehr-costs-tsx" */),
  "component---src-templates-articles-ehr-implementation-ehr-implementation-tsx": () => import("./../../../src/templates/articles/ehr-implementation/ehr-implementation.tsx" /* webpackChunkName: "component---src-templates-articles-ehr-implementation-ehr-implementation-tsx" */),
  "component---src-templates-articles-ehr-interoperability-ehr-interoperability-tsx": () => import("./../../../src/templates/articles/ehr-interoperability/ehr-interoperability.tsx" /* webpackChunkName: "component---src-templates-articles-ehr-interoperability-ehr-interoperability-tsx" */),
  "component---src-templates-articles-ehr-psychiatry-ehr-psychiatry-tsx": () => import("./../../../src/templates/articles/ehr-psychiatry/ehr-psychiatry.tsx" /* webpackChunkName: "component---src-templates-articles-ehr-psychiatry-ehr-psychiatry-tsx" */),
  "component---src-templates-articles-ehr-vs-emr-ehr-vs-emr-tsx": () => import("./../../../src/templates/articles/ehr-vs-emr/ehr-vs-emr.tsx" /* webpackChunkName: "component---src-templates-articles-ehr-vs-emr-ehr-vs-emr-tsx" */),
  "component---src-templates-articles-elearning-gamification-elearning-gamification-tsx": () => import("./../../../src/templates/articles/elearning-gamification/elearning-gamification.tsx" /* webpackChunkName: "component---src-templates-articles-elearning-gamification-elearning-gamification-tsx" */),
  "component---src-templates-articles-enterprise-data-warehousing-enterprise-data-warehousing-tsx": () => import("./../../../src/templates/articles/enterprise-data-warehousing/enterprise-data-warehousing.tsx" /* webpackChunkName: "component---src-templates-articles-enterprise-data-warehousing-enterprise-data-warehousing-tsx" */),
  "component---src-templates-articles-enterprise-iot-enterprise-iot-tsx": () => import("./../../../src/templates/articles/enterprise-iot/enterprise-iot.tsx" /* webpackChunkName: "component---src-templates-articles-enterprise-iot-enterprise-iot-tsx" */),
  "component---src-templates-articles-erp-aerospace-erp-aerospace-tsx": () => import("./../../../src/templates/articles/erp-aerospace/erp-aerospace.tsx" /* webpackChunkName: "component---src-templates-articles-erp-aerospace-erp-aerospace-tsx" */),
  "component---src-templates-articles-erp-best-practices-erp-best-practices-tsx": () => import("./../../../src/templates/articles/erp-best-practices/erp-best-practices.tsx" /* webpackChunkName: "component---src-templates-articles-erp-best-practices-erp-best-practices-tsx" */),
  "component---src-templates-articles-erp-construction-erp-construction-tsx": () => import("./../../../src/templates/articles/erp-construction/erp-construction.tsx" /* webpackChunkName: "component---src-templates-articles-erp-construction-erp-construction-tsx" */),
  "component---src-templates-articles-erp-custom-erp-custom-tsx": () => import("./../../../src/templates/articles/erp-custom/erp-custom.tsx" /* webpackChunkName: "component---src-templates-articles-erp-custom-erp-custom-tsx" */),
  "component---src-templates-articles-erp-ecommerce-erp-ecommerce-tsx": () => import("./../../../src/templates/articles/erp-ecommerce/erp-ecommerce.tsx" /* webpackChunkName: "component---src-templates-articles-erp-ecommerce-erp-ecommerce-tsx" */),
  "component---src-templates-articles-erp-healthcare-erp-healthcare-tsx": () => import("./../../../src/templates/articles/erp-healthcare/erp-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-erp-healthcare-erp-healthcare-tsx" */),
  "component---src-templates-articles-erp-implementation-costs-erp-implementation-costs-tsx": () => import("./../../../src/templates/articles/erp-implementation-costs/erp-implementation-costs.tsx" /* webpackChunkName: "component---src-templates-articles-erp-implementation-costs-erp-implementation-costs-tsx" */),
  "component---src-templates-articles-erp-implementation-erp-implementation-tsx": () => import("./../../../src/templates/articles/erp-implementation/erp-implementation.tsx" /* webpackChunkName: "component---src-templates-articles-erp-implementation-erp-implementation-tsx" */),
  "component---src-templates-articles-erp-implementation-strategy-erp-implementation-strategy-tsx": () => import("./../../../src/templates/articles/erp-implementation-strategy/erp-implementation-strategy.tsx" /* webpackChunkName: "component---src-templates-articles-erp-implementation-strategy-erp-implementation-strategy-tsx" */),
  "component---src-templates-articles-erp-logistics-erp-logistics-tsx": () => import("./../../../src/templates/articles/erp-logistics/erp-logistics.tsx" /* webpackChunkName: "component---src-templates-articles-erp-logistics-erp-logistics-tsx" */),
  "component---src-templates-articles-erp-manufacturing-erp-manufacturing-tsx": () => import("./../../../src/templates/articles/erp-manufacturing/erp-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-erp-manufacturing-erp-manufacturing-tsx" */),
  "component---src-templates-articles-erp-migration-erp-migration-tsx": () => import("./../../../src/templates/articles/erp-migration/erp-migration.tsx" /* webpackChunkName: "component---src-templates-articles-erp-migration-erp-migration-tsx" */),
  "component---src-templates-articles-erp-oil-gas-sector-erp-oil-gas-sector-tsx": () => import("./../../../src/templates/articles/erp-oil-gas-sector/erp-oil-gas-sector.tsx" /* webpackChunkName: "component---src-templates-articles-erp-oil-gas-sector-erp-oil-gas-sector-tsx" */),
  "component---src-templates-articles-erp-real-estate-erp-real-estate-tsx": () => import("./../../../src/templates/articles/erp-real-estate/erp-real-estate.tsx" /* webpackChunkName: "component---src-templates-articles-erp-real-estate-erp-real-estate-tsx" */),
  "component---src-templates-articles-erp-selection-guide-erp-selection-guide-tsx": () => import("./../../../src/templates/articles/erp-selection-guide/erp-selection-guide.tsx" /* webpackChunkName: "component---src-templates-articles-erp-selection-guide-erp-selection-guide-tsx" */),
  "component---src-templates-articles-finance-data-analytics-finance-data-analytics-tsx": () => import("./../../../src/templates/articles/finance-data-analytics/finance-data-analytics.tsx" /* webpackChunkName: "component---src-templates-articles-finance-data-analytics-finance-data-analytics-tsx" */),
  "component---src-templates-articles-finance-modeling-software-finance-modeling-software-tsx": () => import("./../../../src/templates/articles/finance-modeling-software/finance-modeling-software.tsx" /* webpackChunkName: "component---src-templates-articles-finance-modeling-software-finance-modeling-software-tsx" */),
  "component---src-templates-articles-finance-reporting-finance-reporting-tsx": () => import("./../../../src/templates/articles/finance-reporting/finance-reporting.tsx" /* webpackChunkName: "component---src-templates-articles-finance-reporting-finance-reporting-tsx" */),
  "component---src-templates-articles-healthcare-crm-healthcare-crm-tsx": () => import("./../../../src/templates/articles/healthcare-crm/healthcare-crm.tsx" /* webpackChunkName: "component---src-templates-articles-healthcare-crm-healthcare-crm-tsx" */),
  "component---src-templates-articles-healthcare-data-governance-healthcare-data-governance-tsx": () => import("./../../../src/templates/articles/healthcare-data-governance/healthcare-data-governance.tsx" /* webpackChunkName: "component---src-templates-articles-healthcare-data-governance-healthcare-data-governance-tsx" */),
  "component---src-templates-articles-healthcare-ehr-features-healthcare-ehr-features-tsx": () => import("./../../../src/templates/articles/healthcare-ehr-features/healthcare-ehr-features.tsx" /* webpackChunkName: "component---src-templates-articles-healthcare-ehr-features-healthcare-ehr-features-tsx" */),
  "component---src-templates-articles-healthcare-iot-healthcare-iot-tsx": () => import("./../../../src/templates/articles/healthcare-iot/healthcare-iot.tsx" /* webpackChunkName: "component---src-templates-articles-healthcare-iot-healthcare-iot-tsx" */),
  "component---src-templates-articles-healthcare-lims-healthcare-lims-tsx": () => import("./../../../src/templates/articles/healthcare-lims/healthcare-lims.tsx" /* webpackChunkName: "component---src-templates-articles-healthcare-lims-healthcare-lims-tsx" */),
  "component---src-templates-articles-healthcare-pharmacy-automation-healthcare-pharmacy-automation-tsx": () => import("./../../../src/templates/articles/healthcare-pharmacy-automation/healthcare-pharmacy-automation.tsx" /* webpackChunkName: "component---src-templates-articles-healthcare-pharmacy-automation-healthcare-pharmacy-automation-tsx" */),
  "component---src-templates-articles-healthcare-smart-hospital-healthcare-smart-hospital-tsx": () => import("./../../../src/templates/articles/healthcare-smart-hospital/healthcare-smart-hospital.tsx" /* webpackChunkName: "component---src-templates-articles-healthcare-smart-hospital-healthcare-smart-hospital-tsx" */),
  "component---src-templates-articles-healthcare-telemedicine-features-healthcare-telemedicine-features-tsx": () => import("./../../../src/templates/articles/healthcare-telemedicine-features/healthcare-telemedicine-features.tsx" /* webpackChunkName: "component---src-templates-articles-healthcare-telemedicine-features-healthcare-telemedicine-features-tsx" */),
  "component---src-templates-articles-hospital-inventory-management-hospital-inventory-management-tsx": () => import("./../../../src/templates/articles/hospital-inventory-management/hospital-inventory-management.tsx" /* webpackChunkName: "component---src-templates-articles-hospital-inventory-management-hospital-inventory-management-tsx" */),
  "component---src-templates-articles-iot-cases-iot-cases-tsx": () => import("./../../../src/templates/articles/iot-cases/iot-cases.tsx" /* webpackChunkName: "component---src-templates-articles-iot-cases-iot-cases-tsx" */),
  "component---src-templates-articles-iot-data-analytics-iot-data-analytics-tsx": () => import("./../../../src/templates/articles/iot-data-analytics/iot-data-analytics.tsx" /* webpackChunkName: "component---src-templates-articles-iot-data-analytics-iot-data-analytics-tsx" */),
  "component---src-templates-articles-iot-home-automation-iot-home-automation-tsx": () => import("./../../../src/templates/articles/iot-home-automation/iot-home-automation.tsx" /* webpackChunkName: "component---src-templates-articles-iot-home-automation-iot-home-automation-tsx" */),
  "component---src-templates-articles-iot-industrial-security-iot-industrial-security-tsx": () => import("./../../../src/templates/articles/iot-industrial-security/iot-industrial-security.tsx" /* webpackChunkName: "component---src-templates-articles-iot-industrial-security-iot-industrial-security-tsx" */),
  "component---src-templates-articles-iot-manufacturing-iot-manufacturing-tsx": () => import("./../../../src/templates/articles/iot-manufacturing/iot-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-iot-manufacturing-iot-manufacturing-tsx" */),
  "component---src-templates-articles-iot-telecom-iot-telecom-tsx": () => import("./../../../src/templates/articles/iot-telecom/iot-telecom.tsx" /* webpackChunkName: "component---src-templates-articles-iot-telecom-iot-telecom-tsx" */),
  "component---src-templates-articles-knowledge-management-strategy-knowledge-management-strategy-tsx": () => import("./../../../src/templates/articles/knowledge-management-strategy/knowledge-management-strategy.tsx" /* webpackChunkName: "component---src-templates-articles-knowledge-management-strategy-knowledge-management-strategy-tsx" */),
  "component---src-templates-articles-lms-enterprise-lms-enterprise-tsx": () => import("./../../../src/templates/articles/lms-enterprise/lms-enterprise.tsx" /* webpackChunkName: "component---src-templates-articles-lms-enterprise-lms-enterprise-tsx" */),
  "component---src-templates-articles-lms-features-lms-features-tsx": () => import("./../../../src/templates/articles/lms-features/lms-features.tsx" /* webpackChunkName: "component---src-templates-articles-lms-features-lms-features-tsx" */),
  "component---src-templates-articles-mach-architecture-mach-architecture-tsx": () => import("./../../../src/templates/articles/mach-architecture/mach-architecture.tsx" /* webpackChunkName: "component---src-templates-articles-mach-architecture-mach-architecture-tsx" */),
  "component---src-templates-articles-machine-learning-manufacturing-machine-learning-manufacturing-tsx": () => import("./../../../src/templates/articles/machine-learning-manufacturing/machine-learning-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-machine-learning-manufacturing-machine-learning-manufacturing-tsx" */),
  "component---src-templates-articles-ml-agriculture-ml-agriculture-tsx": () => import("./../../../src/templates/articles/ml-agriculture/ml-agriculture.tsx" /* webpackChunkName: "component---src-templates-articles-ml-agriculture-ml-agriculture-tsx" */),
  "component---src-templates-articles-ml-anomaly-detection-ml-anomaly-detection-tsx": () => import("./../../../src/templates/articles/ml-anomaly-detection/ml-anomaly-detection.tsx" /* webpackChunkName: "component---src-templates-articles-ml-anomaly-detection-ml-anomaly-detection-tsx" */),
  "component---src-templates-articles-ml-education-ml-education-tsx": () => import("./../../../src/templates/articles/ml-education/ml-education.tsx" /* webpackChunkName: "component---src-templates-articles-ml-education-ml-education-tsx" */),
  "component---src-templates-articles-ml-fraud-detection-ml-fraud-detection-tsx": () => import("./../../../src/templates/articles/ml-fraud-detection/ml-fraud-detection.tsx" /* webpackChunkName: "component---src-templates-articles-ml-fraud-detection-ml-fraud-detection-tsx" */),
  "component---src-templates-articles-ml-healthcare-ml-healthcare-tsx": () => import("./../../../src/templates/articles/ml-healthcare/ml-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-ml-healthcare-ml-healthcare-tsx" */),
  "component---src-templates-articles-ml-logistics-ml-logistics-tsx": () => import("./../../../src/templates/articles/ml-logistics/ml-logistics.tsx" /* webpackChunkName: "component---src-templates-articles-ml-logistics-ml-logistics-tsx" */),
  "component---src-templates-articles-ml-marketing-ml-marketing-tsx": () => import("./../../../src/templates/articles/ml-marketing/ml-marketing.tsx" /* webpackChunkName: "component---src-templates-articles-ml-marketing-ml-marketing-tsx" */),
  "component---src-templates-articles-ml-real-estate-ml-real-estate-tsx": () => import("./../../../src/templates/articles/ml-real-estate/ml-real-estate.tsx" /* webpackChunkName: "component---src-templates-articles-ml-real-estate-ml-real-estate-tsx" */),
  "component---src-templates-articles-ml-statistics-ml-statistics-tsx": () => import("./../../../src/templates/articles/ml-statistics/ml-statistics.tsx" /* webpackChunkName: "component---src-templates-articles-ml-statistics-ml-statistics-tsx" */),
  "component---src-templates-articles-ml-stock-prediction-ml-stock-prediction-tsx": () => import("./../../../src/templates/articles/ml-stock-prediction/ml-stock-prediction.tsx" /* webpackChunkName: "component---src-templates-articles-ml-stock-prediction-ml-stock-prediction-tsx" */),
  "component---src-templates-articles-ml-use-cases-ml-use-cases-tsx": () => import("./../../../src/templates/articles/ml-use-cases/ml-use-cases.tsx" /* webpackChunkName: "component---src-templates-articles-ml-use-cases-ml-use-cases-tsx" */),
  "component---src-templates-articles-netsuite-salesforce-netsuite-salesforce-tsx": () => import("./../../../src/templates/articles/netsuite-salesforce/netsuite-salesforce.tsx" /* webpackChunkName: "component---src-templates-articles-netsuite-salesforce-netsuite-salesforce-tsx" */),
  "component---src-templates-articles-odoo-manufacturing-odoo-manufacturing-tsx": () => import("./../../../src/templates/articles/odoo-manufacturing/odoo-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-odoo-manufacturing-odoo-manufacturing-tsx" */),
  "component---src-templates-articles-opensource-ehr-opensource-ehr-tsx": () => import("./../../../src/templates/articles/opensource-ehr/opensource-ehr.tsx" /* webpackChunkName: "component---src-templates-articles-opensource-ehr-opensource-ehr-tsx" */),
  "component---src-templates-articles-patient-portals-patient-portals-tsx": () => import("./../../../src/templates/articles/patient-portals/patient-portals.tsx" /* webpackChunkName: "component---src-templates-articles-patient-portals-patient-portals-tsx" */),
  "component---src-templates-articles-pediatric-ehr-pediatric-ehr-tsx": () => import("./../../../src/templates/articles/pediatric-ehr/pediatric-ehr.tsx" /* webpackChunkName: "component---src-templates-articles-pediatric-ehr-pediatric-ehr-tsx" */),
  "component---src-templates-articles-pos-integration-pos-integration-tsx": () => import("./../../../src/templates/articles/pos-integration/pos-integration.tsx" /* webpackChunkName: "component---src-templates-articles-pos-integration-pos-integration-tsx" */),
  "component---src-templates-articles-pos-mobile-pos-mobile-tsx": () => import("./../../../src/templates/articles/pos-mobile/pos-mobile.tsx" /* webpackChunkName: "component---src-templates-articles-pos-mobile-pos-mobile-tsx" */),
  "component---src-templates-articles-predictive-analytics-finance-predictive-analytics-finance-tsx": () => import("./../../../src/templates/articles/predictive-analytics-finance/predictive-analytics-finance.tsx" /* webpackChunkName: "component---src-templates-articles-predictive-analytics-finance-predictive-analytics-finance-tsx" */),
  "component---src-templates-articles-predictive-analytics-healthcare-predictive-analytics-healthcare-tsx": () => import("./../../../src/templates/articles/predictive-analytics-healthcare/predictive-analytics-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-predictive-analytics-healthcare-predictive-analytics-healthcare-tsx" */),
  "component---src-templates-articles-predictive-analytics-manufacturing-predictive-analytics-manufacturing-tsx": () => import("./../../../src/templates/articles/predictive-analytics-manufacturing/predictive-analytics-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-predictive-analytics-manufacturing-predictive-analytics-manufacturing-tsx" */),
  "component---src-templates-articles-predictive-analytics-retail-predictive-analytics-retail-tsx": () => import("./../../../src/templates/articles/predictive-analytics-retail/predictive-analytics-retail.tsx" /* webpackChunkName: "component---src-templates-articles-predictive-analytics-retail-predictive-analytics-retail-tsx" */),
  "component---src-templates-articles-recommendation-systems-recommendation-systems-tsx": () => import("./../../../src/templates/articles/recommendation-systems/recommendation-systems.tsx" /* webpackChunkName: "component---src-templates-articles-recommendation-systems-recommendation-systems-tsx" */),
  "component---src-templates-articles-retail-beacons-retail-beacons-tsx": () => import("./../../../src/templates/articles/retail-beacons/retail-beacons.tsx" /* webpackChunkName: "component---src-templates-articles-retail-beacons-retail-beacons-tsx" */),
  "component---src-templates-articles-retail-crm-retail-crm-tsx": () => import("./../../../src/templates/articles/retail-crm/retail-crm.tsx" /* webpackChunkName: "component---src-templates-articles-retail-crm-retail-crm-tsx" */),
  "component---src-templates-articles-retail-iot-retail-iot-tsx": () => import("./../../../src/templates/articles/retail-iot/retail-iot.tsx" /* webpackChunkName: "component---src-templates-articles-retail-iot-retail-iot-tsx" */),
  "component---src-templates-articles-rpa-automotive-rpa-automotive-tsx": () => import("./../../../src/templates/articles/rpa-automotive/rpa-automotive.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-automotive-rpa-automotive-tsx" */),
  "component---src-templates-articles-rpa-banking-rpa-banking-tsx": () => import("./../../../src/templates/articles/rpa-banking/rpa-banking.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-banking-rpa-banking-tsx" */),
  "component---src-templates-articles-rpa-benefits-rpa-benefits-tsx": () => import("./../../../src/templates/articles/rpa-benefits/rpa-benefits.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-benefits-rpa-benefits-tsx" */),
  "component---src-templates-articles-rpa-estate-rpa-estate-tsx": () => import("./../../../src/templates/articles/rpa-estate/rpa-estate.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-estate-rpa-estate-tsx" */),
  "component---src-templates-articles-rpa-finance-rpa-finance-tsx": () => import("./../../../src/templates/articles/rpa-finance/rpa-finance.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-finance-rpa-finance-tsx" */),
  "component---src-templates-articles-rpa-healthcare-rpa-healthcare-tsx": () => import("./../../../src/templates/articles/rpa-healthcare/rpa-healthcare.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-healthcare-rpa-healthcare-tsx" */),
  "component---src-templates-articles-rpa-hr-rpa-hr-tsx": () => import("./../../../src/templates/articles/rpa-hr/rpa-hr.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-hr-rpa-hr-tsx" */),
  "component---src-templates-articles-rpa-insurance-rpa-insurance-tsx": () => import("./../../../src/templates/articles/rpa-insurance/rpa-insurance.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-insurance-rpa-insurance-tsx" */),
  "component---src-templates-articles-rpa-manufacturing-rpa-manufacturing-tsx": () => import("./../../../src/templates/articles/rpa-manufacturing/rpa-manufacturing.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-manufacturing-rpa-manufacturing-tsx" */),
  "component---src-templates-articles-rpa-uipath-rpa-uipath-tsx": () => import("./../../../src/templates/articles/rpa-uipath/rpa-uipath.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-uipath-rpa-uipath-tsx" */),
  "component---src-templates-articles-rpa-use-cases-rpa-use-cases-tsx": () => import("./../../../src/templates/articles/rpa-use-cases/rpa-use-cases.tsx" /* webpackChunkName: "component---src-templates-articles-rpa-use-cases-rpa-use-cases-tsx" */),
  "component---src-templates-articles-salesforce-best-practices-salesforce-best-practices-tsx": () => import("./../../../src/templates/articles/salesforce-best-practices/salesforce-best-practices.tsx" /* webpackChunkName: "component---src-templates-articles-salesforce-best-practices-salesforce-best-practices-tsx" */),
  "component---src-templates-articles-salesforce-einstein-salesforce-einstein-tsx": () => import("./../../../src/templates/articles/salesforce-einstein/salesforce-einstein.tsx" /* webpackChunkName: "component---src-templates-articles-salesforce-einstein-salesforce-einstein-tsx" */),
  "component---src-templates-articles-salesforce-insurance-salesforce-insurance-tsx": () => import("./../../../src/templates/articles/salesforce-insurance/salesforce-insurance.tsx" /* webpackChunkName: "component---src-templates-articles-salesforce-insurance-salesforce-insurance-tsx" */),
  "component---src-templates-articles-salesforce-real-estate-salesforce-real-estate-tsx": () => import("./../../../src/templates/articles/salesforce-real-estate/salesforce-real-estate.tsx" /* webpackChunkName: "component---src-templates-articles-salesforce-real-estate-salesforce-real-estate-tsx" */),
  "component---src-templates-articles-sd-risks-sd-risks-tsx": () => import("./../../../src/templates/articles/sd-risks/sd-risks.tsx" /* webpackChunkName: "component---src-templates-articles-sd-risks-sd-risks-tsx" */),
  "component---src-templates-articles-services-salesforce-adoption-services-salesforce-adoption-tsx": () => import("./../../../src/templates/articles/services-salesforce-adoption/services-salesforce-adoption.tsx" /* webpackChunkName: "component---src-templates-articles-services-salesforce-adoption-services-salesforce-adoption-tsx" */),
  "component---src-templates-articles-smart-contract-applications-smart-contract-applications-tsx": () => import("./../../../src/templates/articles/smart-contract-applications/smart-contract-applications.tsx" /* webpackChunkName: "component---src-templates-articles-smart-contract-applications-smart-contract-applications-tsx" */),
  "component---src-templates-articles-smart-contract-platforms-smart-contract-platforms-tsx": () => import("./../../../src/templates/articles/smart-contract-platforms/smart-contract-platforms.tsx" /* webpackChunkName: "component---src-templates-articles-smart-contract-platforms-smart-contract-platforms-tsx" */),
  "component---src-templates-articles-software-methodologie-software-methodologie-tsx": () => import("./../../../src/templates/articles/software-methodologie/software-methodologie.tsx" /* webpackChunkName: "component---src-templates-articles-software-methodologie-software-methodologie-tsx" */),
  "component---src-templates-articles-supervised-unsupervised-supervised-unsupervised-tsx": () => import("./../../../src/templates/articles/supervised-unsupervised/supervised-unsupervised.tsx" /* webpackChunkName: "component---src-templates-articles-supervised-unsupervised-supervised-unsupervised-tsx" */),
  "component---src-templates-articles-telemedicine-care-telemedicine-care-tsx": () => import("./../../../src/templates/articles/telemedicine-care/telemedicine-care.tsx" /* webpackChunkName: "component---src-templates-articles-telemedicine-care-telemedicine-care-tsx" */),
  "component---src-templates-articles-telemedicine-dermatology-telemedicine-dermatology-tsx": () => import("./../../../src/templates/articles/telemedicine-dermatology/telemedicine-dermatology.tsx" /* webpackChunkName: "component---src-templates-articles-telemedicine-dermatology-telemedicine-dermatology-tsx" */),
  "component---src-templates-articles-telemedicine-neurology-telemedicine-neurology-tsx": () => import("./../../../src/templates/articles/telemedicine-neurology/telemedicine-neurology.tsx" /* webpackChunkName: "component---src-templates-articles-telemedicine-neurology-telemedicine-neurology-tsx" */),
  "component---src-templates-articles-virtual-hospital-virtual-hospital-tsx": () => import("./../../../src/templates/articles/virtual-hospital/virtual-hospital.tsx" /* webpackChunkName: "component---src-templates-articles-virtual-hospital-virtual-hospital-tsx" */),
  "component---src-templates-articles-visual-inspection-visual-inspection-tsx": () => import("./../../../src/templates/articles/visual-inspection/visual-inspection.tsx" /* webpackChunkName: "component---src-templates-articles-visual-inspection-visual-inspection-tsx" */),
  "component---src-templates-articles-vr-retail-vr-retail-tsx": () => import("./../../../src/templates/articles/vr-retail/vr-retail.tsx" /* webpackChunkName: "component---src-templates-articles-vr-retail-vr-retail-tsx" */),
  "component---src-templates-articles-wealth-management-wealth-management-tsx": () => import("./../../../src/templates/articles/wealth-management/wealth-management.tsx" /* webpackChunkName: "component---src-templates-articles-wealth-management-wealth-management-tsx" */),
  "component---src-templates-blog-blog-post-tsx": () => import("./../../../src/templates/blog/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-blog-post-tsx" */),
  "component---src-templates-experts-experts-tsx": () => import("./../../../src/templates/experts/experts.tsx" /* webpackChunkName: "component---src-templates-experts-experts-tsx" */),
  "component---src-templates-landings-404-404-tsx": () => import("./../../../src/templates/landings/404/404.tsx" /* webpackChunkName: "component---src-templates-landings-404-404-tsx" */),
  "component---src-templates-landings-about-us-about-us-tsx": () => import("./../../../src/templates/landings/about-us/about-us.tsx" /* webpackChunkName: "component---src-templates-landings-about-us-about-us-tsx" */),
  "component---src-templates-landings-acumatica-integration-acumatica-integration-tsx": () => import("./../../../src/templates/landings/acumatica-integration/acumatica-integration.tsx" /* webpackChunkName: "component---src-templates-landings-acumatica-integration-acumatica-integration-tsx" */),
  "component---src-templates-landings-ai-chatbot-development-ai-chatbot-development-tsx": () => import("./../../../src/templates/landings/ai-chatbot-development/ai-chatbot-development.tsx" /* webpackChunkName: "component---src-templates-landings-ai-chatbot-development-ai-chatbot-development-tsx" */),
  "component---src-templates-landings-ai-consulting-ai-consulting-tsx": () => import("./../../../src/templates/landings/ai-consulting/ai-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-ai-consulting-ai-consulting-tsx" */),
  "component---src-templates-landings-ai-services-ai-services-tsx": () => import("./../../../src/templates/landings/ai-services/ai-services.tsx" /* webpackChunkName: "component---src-templates-landings-ai-services-ai-services-tsx" */),
  "component---src-templates-landings-application-development-services-application-development-services-tsx": () => import("./../../../src/templates/landings/application-development-services/application-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-application-development-services-application-development-services-tsx" */),
  "component---src-templates-landings-application-integration-application-integration-tsx": () => import("./../../../src/templates/landings/application-integration/application-integration.tsx" /* webpackChunkName: "component---src-templates-landings-application-integration-application-integration-tsx" */),
  "component---src-templates-landings-application-migration-application-migration-tsx": () => import("./../../../src/templates/landings/application-migration/application-migration.tsx" /* webpackChunkName: "component---src-templates-landings-application-migration-application-migration-tsx" */),
  "component---src-templates-landings-application-modernization-application-modernization-tsx": () => import("./../../../src/templates/landings/application-modernization/application-modernization.tsx" /* webpackChunkName: "component---src-templates-landings-application-modernization-application-modernization-tsx" */),
  "component---src-templates-landings-application-services-application-services-tsx": () => import("./../../../src/templates/landings/application-services/application-services.tsx" /* webpackChunkName: "component---src-templates-landings-application-services-application-services-tsx" */),
  "component---src-templates-landings-approach-approach-tsx": () => import("./../../../src/templates/landings/approach/approach.tsx" /* webpackChunkName: "component---src-templates-landings-approach-approach-tsx" */),
  "component---src-templates-landings-ar-vr-ar-vr-tsx": () => import("./../../../src/templates/landings/ar-vr/ar-vr.tsx" /* webpackChunkName: "component---src-templates-landings-ar-vr-ar-vr-tsx" */),
  "component---src-templates-landings-atlassian-atlassian-tsx": () => import("./../../../src/templates/landings/atlassian/atlassian.tsx" /* webpackChunkName: "component---src-templates-landings-atlassian-atlassian-tsx" */),
  "component---src-templates-landings-atlassian-cost-optimization-atlassian-cost-optimization-tsx": () => import("./../../../src/templates/landings/atlassian-cost-optimization/atlassian-cost-optimization.tsx" /* webpackChunkName: "component---src-templates-landings-atlassian-cost-optimization-atlassian-cost-optimization-tsx" */),
  "component---src-templates-landings-automotive-software-automotive-software-tsx": () => import("./../../../src/templates/landings/automotive-software/automotive-software.tsx" /* webpackChunkName: "component---src-templates-landings-automotive-software-automotive-software-tsx" */),
  "component---src-templates-landings-awards-awards-tsx": () => import("./../../../src/templates/landings/awards/awards.tsx" /* webpackChunkName: "component---src-templates-landings-awards-awards-tsx" */),
  "component---src-templates-landings-aws-consulting-aws-consulting-tsx": () => import("./../../../src/templates/landings/aws-consulting/aws-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-aws-consulting-aws-consulting-tsx" */),
  "component---src-templates-landings-aws-devops-aws-devops-tsx": () => import("./../../../src/templates/landings/aws-devops/aws-devops.tsx" /* webpackChunkName: "component---src-templates-landings-aws-devops-aws-devops-tsx" */),
  "component---src-templates-landings-aws-migration-aws-migration-tsx": () => import("./../../../src/templates/landings/aws-migration/aws-migration.tsx" /* webpackChunkName: "component---src-templates-landings-aws-migration-aws-migration-tsx" */),
  "component---src-templates-landings-azure-consulting-azure-consulting-tsx": () => import("./../../../src/templates/landings/azure-consulting/azure-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-azure-consulting-azure-consulting-tsx" */),
  "component---src-templates-landings-b-2-b-portal-development-b-2-b-portal-development-tsx": () => import("./../../../src/templates/landings/b2b-portal-development/b2b-portal-development.tsx" /* webpackChunkName: "component---src-templates-landings-b-2-b-portal-development-b-2-b-portal-development-tsx" */),
  "component---src-templates-landings-banking-crm-banking-crm-tsx": () => import("./../../../src/templates/landings/banking-crm/banking-crm.tsx" /* webpackChunkName: "component---src-templates-landings-banking-crm-banking-crm-tsx" */),
  "component---src-templates-landings-banking-software-development-banking-software-development-tsx": () => import("./../../../src/templates/landings/banking-software-development/banking-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-banking-software-development-banking-software-development-tsx" */),
  "component---src-templates-landings-bi-consulting-bi-consulting-tsx": () => import("./../../../src/templates/landings/bi-consulting/bi-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-bi-consulting-bi-consulting-tsx" */),
  "component---src-templates-landings-bi-development-bi-development-tsx": () => import("./../../../src/templates/landings/bi-development/bi-development.tsx" /* webpackChunkName: "component---src-templates-landings-bi-development-bi-development-tsx" */),
  "component---src-templates-landings-bi-implementation-bi-implementation-tsx": () => import("./../../../src/templates/landings/bi-implementation/bi-implementation.tsx" /* webpackChunkName: "component---src-templates-landings-bi-implementation-bi-implementation-tsx" */),
  "component---src-templates-landings-bi-tableau-bi-tableau-tsx": () => import("./../../../src/templates/landings/bi-tableau/bi-tableau.tsx" /* webpackChunkName: "component---src-templates-landings-bi-tableau-bi-tableau-tsx" */),
  "component---src-templates-landings-big-data-services-big-data-services-tsx": () => import("./../../../src/templates/landings/big-data-services/big-data-services.tsx" /* webpackChunkName: "component---src-templates-landings-big-data-services-big-data-services-tsx" */),
  "component---src-templates-landings-blockchain-blockchain-tsx": () => import("./../../../src/templates/landings/blockchain/blockchain.tsx" /* webpackChunkName: "component---src-templates-landings-blockchain-blockchain-tsx" */),
  "component---src-templates-landings-blockchain-services-blockchain-services-tsx": () => import("./../../../src/templates/landings/blockchain-services/blockchain-services.tsx" /* webpackChunkName: "component---src-templates-landings-blockchain-services-blockchain-services-tsx" */),
  "component---src-templates-landings-blog-blog-tsx": () => import("./../../../src/templates/landings/blog/blog.tsx" /* webpackChunkName: "component---src-templates-landings-blog-blog-tsx" */),
  "component---src-templates-landings-business-intelligence-services-business-intelligence-services-tsx": () => import("./../../../src/templates/landings/business-intelligence-services/business-intelligence-services.tsx" /* webpackChunkName: "component---src-templates-landings-business-intelligence-services-business-intelligence-services-tsx" */),
  "component---src-templates-landings-business-process-management-business-process-management-tsx": () => import("./../../../src/templates/landings/business-process-management/business-process-management.tsx" /* webpackChunkName: "component---src-templates-landings-business-process-management-business-process-management-tsx" */),
  "component---src-templates-landings-c-plus-c-plus-tsx": () => import("./../../../src/templates/landings/c-plus/c-plus.tsx" /* webpackChunkName: "component---src-templates-landings-c-plus-c-plus-tsx" */),
  "component---src-templates-landings-case-studies-case-studies-tsx": () => import("./../../../src/templates/landings/case-studies/case-studies.tsx" /* webpackChunkName: "component---src-templates-landings-case-studies-case-studies-tsx" */),
  "component---src-templates-landings-cloud-infrastructure-cloud-infrastructure-tsx": () => import("./../../../src/templates/landings/cloud-infrastructure/cloud-infrastructure.tsx" /* webpackChunkName: "component---src-templates-landings-cloud-infrastructure-cloud-infrastructure-tsx" */),
  "component---src-templates-landings-cloud-services-cloud-services-tsx": () => import("./../../../src/templates/landings/cloud-services/cloud-services.tsx" /* webpackChunkName: "component---src-templates-landings-cloud-services-cloud-services-tsx" */),
  "component---src-templates-landings-cloud-services-solutions-cloud-services-solutions-tsx": () => import("./../../../src/templates/landings/cloud-services-solutions/cloud-services-solutions.tsx" /* webpackChunkName: "component---src-templates-landings-cloud-services-solutions-cloud-services-solutions-tsx" */),
  "component---src-templates-landings-community-portals-community-portals-tsx": () => import("./../../../src/templates/landings/community-portals/community-portals.tsx" /* webpackChunkName: "component---src-templates-landings-community-portals-community-portals-tsx" */),
  "component---src-templates-landings-computer-vision-services-computer-vision-services-tsx": () => import("./../../../src/templates/landings/computer-vision-services/computer-vision-services.tsx" /* webpackChunkName: "component---src-templates-landings-computer-vision-services-computer-vision-services-tsx" */),
  "component---src-templates-landings-construction-custom-construction-custom-tsx": () => import("./../../../src/templates/landings/construction-custom/construction-custom.tsx" /* webpackChunkName: "component---src-templates-landings-construction-custom-construction-custom-tsx" */),
  "component---src-templates-landings-contacts-contacts-tsx": () => import("./../../../src/templates/landings/contacts/contacts.tsx" /* webpackChunkName: "component---src-templates-landings-contacts-contacts-tsx" */),
  "component---src-templates-landings-crm-consulting-crm-consulting-tsx": () => import("./../../../src/templates/landings/crm-consulting/crm-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-crm-consulting-crm-consulting-tsx" */),
  "component---src-templates-landings-crm-development-crm-development-tsx": () => import("./../../../src/templates/landings/crm-development/crm-development.tsx" /* webpackChunkName: "component---src-templates-landings-crm-development-crm-development-tsx" */),
  "component---src-templates-landings-crm-implementation-crm-implementation-tsx": () => import("./../../../src/templates/landings/crm-implementation/crm-implementation.tsx" /* webpackChunkName: "component---src-templates-landings-crm-implementation-crm-implementation-tsx" */),
  "component---src-templates-landings-crm-microsoft-dynamics-crm-microsoft-dynamics-tsx": () => import("./../../../src/templates/landings/crm-microsoft-dynamics/crm-microsoft-dynamics.tsx" /* webpackChunkName: "component---src-templates-landings-crm-microsoft-dynamics-crm-microsoft-dynamics-tsx" */),
  "component---src-templates-landings-crm-salesforce-development-crm-salesforce-development-tsx": () => import("./../../../src/templates/landings/crm-salesforce-development/crm-salesforce-development.tsx" /* webpackChunkName: "component---src-templates-landings-crm-salesforce-development-crm-salesforce-development-tsx" */),
  "component---src-templates-landings-crm-services-crm-services-tsx": () => import("./../../../src/templates/landings/crm-services/crm-services.tsx" /* webpackChunkName: "component---src-templates-landings-crm-services-crm-services-tsx" */),
  "component---src-templates-landings-custom-enterprise-software-custom-enterprise-software-tsx": () => import("./../../../src/templates/landings/custom-enterprise-software/custom-enterprise-software.tsx" /* webpackChunkName: "component---src-templates-landings-custom-enterprise-software-custom-enterprise-software-tsx" */),
  "component---src-templates-landings-custom-insurance-custom-insurance-tsx": () => import("./../../../src/templates/landings/custom-insurance/custom-insurance.tsx" /* webpackChunkName: "component---src-templates-landings-custom-insurance-custom-insurance-tsx" */),
  "component---src-templates-landings-custom-lms-development-custom-lms-development-tsx": () => import("./../../../src/templates/landings/custom-lms-development/custom-lms-development.tsx" /* webpackChunkName: "component---src-templates-landings-custom-lms-development-custom-lms-development-tsx" */),
  "component---src-templates-landings-custom-software-development-custom-software-development-tsx": () => import("./../../../src/templates/landings/custom-software-development/custom-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-custom-software-development-custom-software-development-tsx" */),
  "component---src-templates-landings-customer-consulting-customer-consulting-tsx": () => import("./../../../src/templates/landings/customer-consulting/customer-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-customer-consulting-customer-consulting-tsx" */),
  "component---src-templates-landings-customer-data-analytics-customer-data-analytics-tsx": () => import("./../../../src/templates/landings/customer-data-analytics/customer-data-analytics.tsx" /* webpackChunkName: "component---src-templates-landings-customer-data-analytics-customer-data-analytics-tsx" */),
  "component---src-templates-landings-customer-portal-customer-portal-tsx": () => import("./../../../src/templates/landings/customer-portal/customer-portal.tsx" /* webpackChunkName: "component---src-templates-landings-customer-portal-customer-portal-tsx" */),
  "component---src-templates-landings-cyber-security-cyber-security-tsx": () => import("./../../../src/templates/landings/cyber-security/cyber-security.tsx" /* webpackChunkName: "component---src-templates-landings-cyber-security-cyber-security-tsx" */),
  "component---src-templates-landings-data-science-consulting-data-science-consulting-tsx": () => import("./../../../src/templates/landings/data-science-consulting/data-science-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-data-science-consulting-data-science-consulting-tsx" */),
  "component---src-templates-landings-data-visualization-data-visualization-tsx": () => import("./../../../src/templates/landings/data-visualization/data-visualization.tsx" /* webpackChunkName: "component---src-templates-landings-data-visualization-data-visualization-tsx" */),
  "component---src-templates-landings-data-warehouse-data-warehouse-tsx": () => import("./../../../src/templates/landings/data-warehouse/data-warehouse.tsx" /* webpackChunkName: "component---src-templates-landings-data-warehouse-data-warehouse-tsx" */),
  "component---src-templates-landings-developers-angular-developers-angular-tsx": () => import("./../../../src/templates/landings/developers-angular/developers-angular.tsx" /* webpackChunkName: "component---src-templates-landings-developers-angular-developers-angular-tsx" */),
  "component---src-templates-landings-developers-django-developers-django-tsx": () => import("./../../../src/templates/landings/developers-django/developers-django.tsx" /* webpackChunkName: "component---src-templates-landings-developers-django-developers-django-tsx" */),
  "component---src-templates-landings-developers-flutter-developers-flutter-tsx": () => import("./../../../src/templates/landings/developers-flutter/developers-flutter.tsx" /* webpackChunkName: "component---src-templates-landings-developers-flutter-developers-flutter-tsx" */),
  "component---src-templates-landings-developers-nodejs-developers-nodejs-tsx": () => import("./../../../src/templates/landings/developers-nodejs/developers-nodejs.tsx" /* webpackChunkName: "component---src-templates-landings-developers-nodejs-developers-nodejs-tsx" */),
  "component---src-templates-landings-developers-symfony-developers-symfony-tsx": () => import("./../../../src/templates/landings/developers-symfony/developers-symfony.tsx" /* webpackChunkName: "component---src-templates-landings-developers-symfony-developers-symfony-tsx" */),
  "component---src-templates-landings-developers-vue-developers-vue-tsx": () => import("./../../../src/templates/landings/developers-vue/developers-vue.tsx" /* webpackChunkName: "component---src-templates-landings-developers-vue-developers-vue-tsx" */),
  "component---src-templates-landings-devops-consulting-devops-consulting-tsx": () => import("./../../../src/templates/landings/devops-consulting/devops-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-devops-consulting-devops-consulting-tsx" */),
  "component---src-templates-landings-digital-commerce-digital-commerce-tsx": () => import("./../../../src/templates/landings/digital-commerce/digital-commerce.tsx" /* webpackChunkName: "component---src-templates-landings-digital-commerce-digital-commerce-tsx" */),
  "component---src-templates-landings-digital-enterprise-digital-enterprise-tsx": () => import("./../../../src/templates/landings/digital-enterprise/digital-enterprise.tsx" /* webpackChunkName: "component---src-templates-landings-digital-enterprise-digital-enterprise-tsx" */),
  "component---src-templates-landings-digital-workplace-digital-workplace-tsx": () => import("./../../../src/templates/landings/digital-workplace/digital-workplace.tsx" /* webpackChunkName: "component---src-templates-landings-digital-workplace-digital-workplace-tsx" */),
  "component---src-templates-landings-document-management-document-management-tsx": () => import("./../../../src/templates/landings/document-management/document-management.tsx" /* webpackChunkName: "component---src-templates-landings-document-management-document-management-tsx" */),
  "component---src-templates-landings-dynamics-365-integration-dynamics-365-integration-tsx": () => import("./../../../src/templates/landings/dynamics-365-integration/dynamics-365-integration.tsx" /* webpackChunkName: "component---src-templates-landings-dynamics-365-integration-dynamics-365-integration-tsx" */),
  "component---src-templates-landings-dynamics-business-central-dynamics-business-central-tsx": () => import("./../../../src/templates/landings/dynamics-business-central/dynamics-business-central.tsx" /* webpackChunkName: "component---src-templates-landings-dynamics-business-central-dynamics-business-central-tsx" */),
  "component---src-templates-landings-dynamics-support-dynamics-support-tsx": () => import("./../../../src/templates/landings/dynamics-support/dynamics-support.tsx" /* webpackChunkName: "component---src-templates-landings-dynamics-support-dynamics-support-tsx" */),
  "component---src-templates-landings-ecommerce-consulting-ecommerce-consulting-tsx": () => import("./../../../src/templates/landings/ecommerce-consulting/ecommerce-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-ecommerce-consulting-ecommerce-consulting-tsx" */),
  "component---src-templates-landings-ecommerce-crm-ecommerce-crm-tsx": () => import("./../../../src/templates/landings/ecommerce-crm/ecommerce-crm.tsx" /* webpackChunkName: "component---src-templates-landings-ecommerce-crm-ecommerce-crm-tsx" */),
  "component---src-templates-landings-ecommerce-data-analytics-ecommerce-data-analytics-tsx": () => import("./../../../src/templates/landings/ecommerce-data-analytics/ecommerce-data-analytics.tsx" /* webpackChunkName: "component---src-templates-landings-ecommerce-data-analytics-ecommerce-data-analytics-tsx" */),
  "component---src-templates-landings-ecommerce-development-ecommerce-development-tsx": () => import("./../../../src/templates/landings/ecommerce-development/ecommerce-development.tsx" /* webpackChunkName: "component---src-templates-landings-ecommerce-development-ecommerce-development-tsx" */),
  "component---src-templates-landings-ecommerce-portal-development-ecommerce-portal-development-tsx": () => import("./../../../src/templates/landings/ecommerce-portal-development/ecommerce-portal-development.tsx" /* webpackChunkName: "component---src-templates-landings-ecommerce-portal-development-ecommerce-portal-development-tsx" */),
  "component---src-templates-landings-ecommerce-saas-ecommerce-saas-tsx": () => import("./../../../src/templates/landings/ecommerce-saas/ecommerce-saas.tsx" /* webpackChunkName: "component---src-templates-landings-ecommerce-saas-ecommerce-saas-tsx" */),
  "component---src-templates-landings-education-analytics-education-analytics-tsx": () => import("./../../../src/templates/landings/education-analytics/education-analytics.tsx" /* webpackChunkName: "component---src-templates-landings-education-analytics-education-analytics-tsx" */),
  "component---src-templates-landings-ehr-integration-services-ehr-integration-services-tsx": () => import("./../../../src/templates/landings/ehr-integration-services/ehr-integration-services.tsx" /* webpackChunkName: "component---src-templates-landings-ehr-integration-services-ehr-integration-services-tsx" */),
  "component---src-templates-landings-elearning-mobile-elearning-mobile-tsx": () => import("./../../../src/templates/landings/elearning-mobile/elearning-mobile.tsx" /* webpackChunkName: "component---src-templates-landings-elearning-mobile-elearning-mobile-tsx" */),
  "component---src-templates-landings-elearning-portals-elearning-portals-tsx": () => import("./../../../src/templates/landings/elearning-portals/elearning-portals.tsx" /* webpackChunkName: "component---src-templates-landings-elearning-portals-elearning-portals-tsx" */),
  "component---src-templates-landings-elearning-software-elearning-software-tsx": () => import("./../../../src/templates/landings/elearning-software/elearning-software.tsx" /* webpackChunkName: "component---src-templates-landings-elearning-software-elearning-software-tsx" */),
  "component---src-templates-landings-employee-portals-development-employee-portals-development-tsx": () => import("./../../../src/templates/landings/employee-portals-development/employee-portals-development.tsx" /* webpackChunkName: "component---src-templates-landings-employee-portals-development-employee-portals-development-tsx" */),
  "component---src-templates-landings-emr-ehr-development-emr-ehr-development-tsx": () => import("./../../../src/templates/landings/emr-ehr-development/emr-ehr-development.tsx" /* webpackChunkName: "component---src-templates-landings-emr-ehr-development-emr-ehr-development-tsx" */),
  "component---src-templates-landings-enterprise-collaboration-software-enterprise-collaboration-software-tsx": () => import("./../../../src/templates/landings/enterprise-collaboration-software/enterprise-collaboration-software.tsx" /* webpackChunkName: "component---src-templates-landings-enterprise-collaboration-software-enterprise-collaboration-software-tsx" */),
  "component---src-templates-landings-enterprise-content-management-consulting-enterprise-content-management-consulting-tsx": () => import("./../../../src/templates/landings/enterprise-content-management-consulting/enterprise-content-management-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-enterprise-content-management-consulting-enterprise-content-management-consulting-tsx" */),
  "component---src-templates-landings-erp-automotive-erp-automotive-tsx": () => import("./../../../src/templates/landings/erp-automotive/erp-automotive.tsx" /* webpackChunkName: "component---src-templates-landings-erp-automotive-erp-automotive-tsx" */),
  "component---src-templates-landings-erp-consulting-erp-consulting-tsx": () => import("./../../../src/templates/landings/erp-consulting/erp-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-erp-consulting-erp-consulting-tsx" */),
  "component---src-templates-landings-erp-customization-erp-customization-tsx": () => import("./../../../src/templates/landings/erp-customization/erp-customization.tsx" /* webpackChunkName: "component---src-templates-landings-erp-customization-erp-customization-tsx" */),
  "component---src-templates-landings-erp-development-erp-development-tsx": () => import("./../../../src/templates/landings/erp-development/erp-development.tsx" /* webpackChunkName: "component---src-templates-landings-erp-development-erp-development-tsx" */),
  "component---src-templates-landings-erp-dynamics-services-erp-dynamics-services-tsx": () => import("./../../../src/templates/landings/erp-dynamics-services/erp-dynamics-services.tsx" /* webpackChunkName: "component---src-templates-landings-erp-dynamics-services-erp-dynamics-services-tsx" */),
  "component---src-templates-landings-erp-implementation-services-erp-implementation-services-tsx": () => import("./../../../src/templates/landings/erp-implementation-services/erp-implementation-services.tsx" /* webpackChunkName: "component---src-templates-landings-erp-implementation-services-erp-implementation-services-tsx" */),
  "component---src-templates-landings-erp-insurance-erp-insurance-tsx": () => import("./../../../src/templates/landings/erp-insurance/erp-insurance.tsx" /* webpackChunkName: "component---src-templates-landings-erp-insurance-erp-insurance-tsx" */),
  "component---src-templates-landings-erp-integration-erp-integration-tsx": () => import("./../../../src/templates/landings/erp-integration/erp-integration.tsx" /* webpackChunkName: "component---src-templates-landings-erp-integration-erp-integration-tsx" */),
  "component---src-templates-landings-erp-netsuite-erp-netsuite-tsx": () => import("./../../../src/templates/landings/erp-netsuite/erp-netsuite.tsx" /* webpackChunkName: "component---src-templates-landings-erp-netsuite-erp-netsuite-tsx" */),
  "component---src-templates-landings-erp-services-solutions-erp-services-solutions-tsx": () => import("./../../../src/templates/landings/erp-services-solutions/erp-services-solutions.tsx" /* webpackChunkName: "component---src-templates-landings-erp-services-solutions-erp-services-solutions-tsx" */),
  "component---src-templates-landings-experts-experts-tsx": () => import("./../../../src/templates/landings/experts/experts.tsx" /* webpackChunkName: "component---src-templates-landings-experts-experts-tsx" */),
  "component---src-templates-landings-finance-fintech-finance-fintech-tsx": () => import("./../../../src/templates/landings/finance-fintech/finance-fintech.tsx" /* webpackChunkName: "component---src-templates-landings-finance-fintech-finance-fintech-tsx" */),
  "component---src-templates-landings-financial-software-development-financial-software-development-tsx": () => import("./../../../src/templates/landings/financial-software-development/financial-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-financial-software-development-financial-software-development-tsx" */),
  "component---src-templates-landings-global-outsourcing-100-global-outsourcing-100-tsx": () => import("./../../../src/templates/landings/global-outsourcing-100/global-outsourcing-100.tsx" /* webpackChunkName: "component---src-templates-landings-global-outsourcing-100-global-outsourcing-100-tsx" */),
  "component---src-templates-landings-healthcare-data-analytics-healthcare-data-analytics-tsx": () => import("./../../../src/templates/landings/healthcare-data-analytics/healthcare-data-analytics.tsx" /* webpackChunkName: "component---src-templates-landings-healthcare-data-analytics-healthcare-data-analytics-tsx" */),
  "component---src-templates-landings-healthcare-ecommerce-healthcare-ecommerce-tsx": () => import("./../../../src/templates/landings/healthcare-ecommerce/healthcare-ecommerce.tsx" /* webpackChunkName: "component---src-templates-landings-healthcare-ecommerce-healthcare-ecommerce-tsx" */),
  "component---src-templates-landings-healthcare-microsoft-cloud-healthcare-microsoft-cloud-tsx": () => import("./../../../src/templates/landings/healthcare-microsoft-cloud/healthcare-microsoft-cloud.tsx" /* webpackChunkName: "component---src-templates-landings-healthcare-microsoft-cloud-healthcare-microsoft-cloud-tsx" */),
  "component---src-templates-landings-homepage-landing-homepage-landing-tsx": () => import("./../../../src/templates/landings/homepage-landing/homepage-landing.tsx" /* webpackChunkName: "component---src-templates-landings-homepage-landing-homepage-landing-tsx" */),
  "component---src-templates-landings-hospital-management-software-hospital-management-software-tsx": () => import("./../../../src/templates/landings/hospital-management-software/hospital-management-software.tsx" /* webpackChunkName: "component---src-templates-landings-hospital-management-software-hospital-management-software-tsx" */),
  "component---src-templates-landings-hr-data-analytics-hr-data-analytics-tsx": () => import("./../../../src/templates/landings/hr-data-analytics/hr-data-analytics.tsx" /* webpackChunkName: "component---src-templates-landings-hr-data-analytics-hr-data-analytics-tsx" */),
  "component---src-templates-landings-hr-software-hr-software-tsx": () => import("./../../../src/templates/landings/hr-software/hr-software.tsx" /* webpackChunkName: "component---src-templates-landings-hr-software-hr-software-tsx" */),
  "component---src-templates-landings-impressum-impressum-tsx": () => import("./../../../src/templates/landings/impressum/impressum.tsx" /* webpackChunkName: "component---src-templates-landings-impressum-impressum-tsx" */),
  "component---src-templates-landings-industries-industries-tsx": () => import("./../../../src/templates/landings/industries/industries.tsx" /* webpackChunkName: "component---src-templates-landings-industries-industries-tsx" */),
  "component---src-templates-landings-insurance-policy-management-insurance-policy-management-tsx": () => import("./../../../src/templates/landings/insurance-policy-management/insurance-policy-management.tsx" /* webpackChunkName: "component---src-templates-landings-insurance-policy-management-insurance-policy-management-tsx" */),
  "component---src-templates-landings-internet-of-things-internet-of-things-tsx": () => import("./../../../src/templates/landings/internet-of-things/internet-of-things.tsx" /* webpackChunkName: "component---src-templates-landings-internet-of-things-internet-of-things-tsx" */),
  "component---src-templates-landings-iot-industrial-services-iot-industrial-services-tsx": () => import("./../../../src/templates/landings/iot-industrial-services/iot-industrial-services.tsx" /* webpackChunkName: "component---src-templates-landings-iot-industrial-services-iot-industrial-services-tsx" */),
  "component---src-templates-landings-it-consulting-it-consulting-tsx": () => import("./../../../src/templates/landings/it-consulting/it-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-it-consulting-it-consulting-tsx" */),
  "component---src-templates-landings-java-development-java-development-tsx": () => import("./../../../src/templates/landings/java-development/java-development.tsx" /* webpackChunkName: "component---src-templates-landings-java-development-java-development-tsx" */),
  "component---src-templates-landings-kotlin-development-kotlin-development-tsx": () => import("./../../../src/templates/landings/kotlin-development/kotlin-development.tsx" /* webpackChunkName: "component---src-templates-landings-kotlin-development-kotlin-development-tsx" */),
  "component---src-templates-landings-learning-content-management-systems-learning-content-management-systems-tsx": () => import("./../../../src/templates/landings/learning-content-management-systems/learning-content-management-systems.tsx" /* webpackChunkName: "component---src-templates-landings-learning-content-management-systems-learning-content-management-systems-tsx" */),
  "component---src-templates-landings-lms-consulting-lms-consulting-tsx": () => import("./../../../src/templates/landings/lms-consulting/lms-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-lms-consulting-lms-consulting-tsx" */),
  "component---src-templates-landings-logistics-software-logistics-software-tsx": () => import("./../../../src/templates/landings/logistics-software/logistics-software.tsx" /* webpackChunkName: "component---src-templates-landings-logistics-software-logistics-software-tsx" */),
  "component---src-templates-landings-machine-learning-machine-learning-tsx": () => import("./../../../src/templates/landings/machine-learning/machine-learning.tsx" /* webpackChunkName: "component---src-templates-landings-machine-learning-machine-learning-tsx" */),
  "component---src-templates-landings-magento-development-services-magento-development-services-tsx": () => import("./../../../src/templates/landings/magento-development-services/magento-development-services.tsx" /* webpackChunkName: "component---src-templates-landings-magento-development-services-magento-development-services-tsx" */),
  "component---src-templates-landings-maintenance-maintenance-tsx": () => import("./../../../src/templates/landings/maintenance/maintenance.tsx" /* webpackChunkName: "component---src-templates-landings-maintenance-maintenance-tsx" */),
  "component---src-templates-landings-managed-it-services-managed-it-services-tsx": () => import("./../../../src/templates/landings/managed-it-services/managed-it-services.tsx" /* webpackChunkName: "component---src-templates-landings-managed-it-services-managed-it-services-tsx" */),
  "component---src-templates-landings-manufacturing-custom-manufacturing-custom-tsx": () => import("./../../../src/templates/landings/manufacturing-custom/manufacturing-custom.tsx" /* webpackChunkName: "component---src-templates-landings-manufacturing-custom-manufacturing-custom-tsx" */),
  "component---src-templates-landings-manufacturing-manufacturing-tsx": () => import("./../../../src/templates/landings/manufacturing/manufacturing.tsx" /* webpackChunkName: "component---src-templates-landings-manufacturing-manufacturing-tsx" */),
  "component---src-templates-landings-marketplace-development-marketplace-development-tsx": () => import("./../../../src/templates/landings/marketplace-development/marketplace-development.tsx" /* webpackChunkName: "component---src-templates-landings-marketplace-development-marketplace-development-tsx" */),
  "component---src-templates-landings-medical-device-software-development-medical-device-software-development-tsx": () => import("./../../../src/templates/landings/medical-device-software-development/medical-device-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-medical-device-software-development-medical-device-software-development-tsx" */),
  "component---src-templates-landings-medical-image-analysis-medical-image-analysis-tsx": () => import("./../../../src/templates/landings/medical-image-analysis/medical-image-analysis.tsx" /* webpackChunkName: "component---src-templates-landings-medical-image-analysis-medical-image-analysis-tsx" */),
  "component---src-templates-landings-microsoft-consulting-microsoft-consulting-tsx": () => import("./../../../src/templates/landings/microsoft-consulting/microsoft-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-microsoft-consulting-microsoft-consulting-tsx" */),
  "component---src-templates-landings-ml-development-ml-development-tsx": () => import("./../../../src/templates/landings/ml-development/ml-development.tsx" /* webpackChunkName: "component---src-templates-landings-ml-development-ml-development-tsx" */),
  "component---src-templates-landings-ml-experts-ml-experts-tsx": () => import("./../../../src/templates/landings/ml-experts/ml-experts.tsx" /* webpackChunkName: "component---src-templates-landings-ml-experts-ml-experts-tsx" */),
  "component---src-templates-landings-mobile-app-dev-mobile-app-dev-tsx": () => import("./../../../src/templates/landings/mobile-app-dev/mobile-app-dev.tsx" /* webpackChunkName: "component---src-templates-landings-mobile-app-dev-mobile-app-dev-tsx" */),
  "component---src-templates-landings-mobile-banking-application-development-mobile-banking-application-development-tsx": () => import("./../../../src/templates/landings/mobile-banking-application-development/mobile-banking-application-development.tsx" /* webpackChunkName: "component---src-templates-landings-mobile-banking-application-development-mobile-banking-application-development-tsx" */),
  "component---src-templates-landings-mobile-healthcare-mobile-healthcare-tsx": () => import("./../../../src/templates/landings/mobile-healthcare/mobile-healthcare.tsx" /* webpackChunkName: "component---src-templates-landings-mobile-healthcare-mobile-healthcare-tsx" */),
  "component---src-templates-landings-news-news-tsx": () => import("./../../../src/templates/landings/news/news.tsx" /* webpackChunkName: "component---src-templates-landings-news-news-tsx" */),
  "component---src-templates-landings-nursing-ehr-nursing-ehr-tsx": () => import("./../../../src/templates/landings/nursing-ehr/nursing-ehr.tsx" /* webpackChunkName: "component---src-templates-landings-nursing-ehr-nursing-ehr-tsx" */),
  "component---src-templates-landings-odoo-customization-odoo-customization-tsx": () => import("./../../../src/templates/landings/odoo-customization/odoo-customization.tsx" /* webpackChunkName: "component---src-templates-landings-odoo-customization-odoo-customization-tsx" */),
  "component---src-templates-landings-odoo-development-odoo-development-tsx": () => import("./../../../src/templates/landings/odoo-development/odoo-development.tsx" /* webpackChunkName: "component---src-templates-landings-odoo-development-odoo-development-tsx" */),
  "component---src-templates-landings-odoo-implementation-odoo-implementation-tsx": () => import("./../../../src/templates/landings/odoo-implementation/odoo-implementation.tsx" /* webpackChunkName: "component---src-templates-landings-odoo-implementation-odoo-implementation-tsx" */),
  "component---src-templates-landings-odoo-integration-odoo-integration-tsx": () => import("./../../../src/templates/landings/odoo-integration/odoo-integration.tsx" /* webpackChunkName: "component---src-templates-landings-odoo-integration-odoo-integration-tsx" */),
  "component---src-templates-landings-office-365-office-365-tsx": () => import("./../../../src/templates/landings/office-365/office-365.tsx" /* webpackChunkName: "component---src-templates-landings-office-365-office-365-tsx" */),
  "component---src-templates-landings-open-positions-open-positions-tsx": () => import("./../../../src/templates/landings/open-positions/open-positions.tsx" /* webpackChunkName: "component---src-templates-landings-open-positions-open-positions-tsx" */),
  "component---src-templates-landings-partnership-partnership-tsx": () => import("./../../../src/templates/landings/partnership/partnership.tsx" /* webpackChunkName: "component---src-templates-landings-partnership-partnership-tsx" */),
  "component---src-templates-landings-patient-engagement-patient-engagement-tsx": () => import("./../../../src/templates/landings/patient-engagement/patient-engagement.tsx" /* webpackChunkName: "component---src-templates-landings-patient-engagement-patient-engagement-tsx" */),
  "component---src-templates-landings-pega-pega-tsx": () => import("./../../../src/templates/landings/pega/pega.tsx" /* webpackChunkName: "component---src-templates-landings-pega-pega-tsx" */),
  "component---src-templates-landings-pharmacy-management-software-pharmacy-management-software-tsx": () => import("./../../../src/templates/landings/pharmacy-management-software/pharmacy-management-software.tsx" /* webpackChunkName: "component---src-templates-landings-pharmacy-management-software-pharmacy-management-software-tsx" */),
  "component---src-templates-landings-portals-insurance-portals-insurance-tsx": () => import("./../../../src/templates/landings/portals-insurance/portals-insurance.tsx" /* webpackChunkName: "component---src-templates-landings-portals-insurance-portals-insurance-tsx" */),
  "component---src-templates-landings-power-bi-consulting-power-bi-consulting-tsx": () => import("./../../../src/templates/landings/power-bi-consulting/power-bi-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-power-bi-consulting-power-bi-consulting-tsx" */),
  "component---src-templates-landings-predictive-analytics-predictive-analytics-tsx": () => import("./../../../src/templates/landings/predictive-analytics/predictive-analytics.tsx" /* webpackChunkName: "component---src-templates-landings-predictive-analytics-predictive-analytics-tsx" */),
  "component---src-templates-landings-privacy-notice-privacy-notice-tsx": () => import("./../../../src/templates/landings/privacy-notice/privacy-notice.tsx" /* webpackChunkName: "component---src-templates-landings-privacy-notice-privacy-notice-tsx" */),
  "component---src-templates-landings-python-development-python-development-tsx": () => import("./../../../src/templates/landings/python-development/python-development.tsx" /* webpackChunkName: "component---src-templates-landings-python-development-python-development-tsx" */),
  "component---src-templates-landings-qa-testing-qa-testing-tsx": () => import("./../../../src/templates/landings/qa-testing/qa-testing.tsx" /* webpackChunkName: "component---src-templates-landings-qa-testing-qa-testing-tsx" */),
  "component---src-templates-landings-quality-management-quality-management-tsx": () => import("./../../../src/templates/landings/quality-management/quality-management.tsx" /* webpackChunkName: "component---src-templates-landings-quality-management-quality-management-tsx" */),
  "component---src-templates-landings-react-development-react-development-tsx": () => import("./../../../src/templates/landings/react-development/react-development.tsx" /* webpackChunkName: "component---src-templates-landings-react-development-react-development-tsx" */),
  "component---src-templates-landings-react-native-react-native-tsx": () => import("./../../../src/templates/landings/react-native/react-native.tsx" /* webpackChunkName: "component---src-templates-landings-react-native-react-native-tsx" */),
  "component---src-templates-landings-real-estate-data-analytics-real-estate-data-analytics-tsx": () => import("./../../../src/templates/landings/real-estate-data-analytics/real-estate-data-analytics.tsx" /* webpackChunkName: "component---src-templates-landings-real-estate-data-analytics-real-estate-data-analytics-tsx" */),
  "component---src-templates-landings-real-estate-portals-real-estate-portals-tsx": () => import("./../../../src/templates/landings/real-estate-portals/real-estate-portals.tsx" /* webpackChunkName: "component---src-templates-landings-real-estate-portals-real-estate-portals-tsx" */),
  "component---src-templates-landings-real-estate-real-estate-tsx": () => import("./../../../src/templates/landings/real-estate/real-estate.tsx" /* webpackChunkName: "component---src-templates-landings-real-estate-real-estate-tsx" */),
  "component---src-templates-landings-reinsurance-software-reinsurance-software-tsx": () => import("./../../../src/templates/landings/reinsurance-software/reinsurance-software.tsx" /* webpackChunkName: "component---src-templates-landings-reinsurance-software-reinsurance-software-tsx" */),
  "component---src-templates-landings-remote-patient-monitoring-remote-patient-monitoring-tsx": () => import("./../../../src/templates/landings/remote-patient-monitoring/remote-patient-monitoring.tsx" /* webpackChunkName: "component---src-templates-landings-remote-patient-monitoring-remote-patient-monitoring-tsx" */),
  "component---src-templates-landings-retail-data-analytics-retail-data-analytics-tsx": () => import("./../../../src/templates/landings/retail-data-analytics/retail-data-analytics.tsx" /* webpackChunkName: "component---src-templates-landings-retail-data-analytics-retail-data-analytics-tsx" */),
  "component---src-templates-landings-retail-erp-retail-erp-tsx": () => import("./../../../src/templates/landings/retail-erp/retail-erp.tsx" /* webpackChunkName: "component---src-templates-landings-retail-erp-retail-erp-tsx" */),
  "component---src-templates-landings-retail-pos-retail-pos-tsx": () => import("./../../../src/templates/landings/retail-pos/retail-pos.tsx" /* webpackChunkName: "component---src-templates-landings-retail-pos-retail-pos-tsx" */),
  "component---src-templates-landings-retail-retail-tsx": () => import("./../../../src/templates/landings/retail/retail.tsx" /* webpackChunkName: "component---src-templates-landings-retail-retail-tsx" */),
  "component---src-templates-landings-rpa-consulting-rpa-consulting-tsx": () => import("./../../../src/templates/landings/rpa-consulting/rpa-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-rpa-consulting-rpa-consulting-tsx" */),
  "component---src-templates-landings-ruby-development-ruby-development-tsx": () => import("./../../../src/templates/landings/ruby-development/ruby-development.tsx" /* webpackChunkName: "component---src-templates-landings-ruby-development-ruby-development-tsx" */),
  "component---src-templates-landings-saas-development-saas-development-tsx": () => import("./../../../src/templates/landings/saas-development/saas-development.tsx" /* webpackChunkName: "component---src-templates-landings-saas-development-saas-development-tsx" */),
  "component---src-templates-landings-sales-microsoft-dynamics-sales-microsoft-dynamics-tsx": () => import("./../../../src/templates/landings/sales-microsoft-dynamics/sales-microsoft-dynamics.tsx" /* webpackChunkName: "component---src-templates-landings-sales-microsoft-dynamics-sales-microsoft-dynamics-tsx" */),
  "component---src-templates-landings-salesforce-consulting-salesforce-consulting-tsx": () => import("./../../../src/templates/landings/salesforce-consulting/salesforce-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-salesforce-consulting-salesforce-consulting-tsx" */),
  "component---src-templates-landings-salesforce-consulting-service-salesforce-consulting-service-tsx": () => import("./../../../src/templates/landings/salesforce-consulting-service/salesforce-consulting-service.tsx" /* webpackChunkName: "component---src-templates-landings-salesforce-consulting-service-salesforce-consulting-service-tsx" */),
  "component---src-templates-landings-salesforce-customization-salesforce-customization-tsx": () => import("./../../../src/templates/landings/salesforce-customization/salesforce-customization.tsx" /* webpackChunkName: "component---src-templates-landings-salesforce-customization-salesforce-customization-tsx" */),
  "component---src-templates-landings-salesforce-data-migration-salesforce-data-migration-tsx": () => import("./../../../src/templates/landings/salesforce-data-migration/salesforce-data-migration.tsx" /* webpackChunkName: "component---src-templates-landings-salesforce-data-migration-salesforce-data-migration-tsx" */),
  "component---src-templates-landings-salesforce-implementation-salesforce-implementation-tsx": () => import("./../../../src/templates/landings/salesforce-implementation/salesforce-implementation.tsx" /* webpackChunkName: "component---src-templates-landings-salesforce-implementation-salesforce-implementation-tsx" */),
  "component---src-templates-landings-salesforce-integration-salesforce-integration-tsx": () => import("./../../../src/templates/landings/salesforce-integration/salesforce-integration.tsx" /* webpackChunkName: "component---src-templates-landings-salesforce-integration-salesforce-integration-tsx" */),
  "component---src-templates-landings-salesforce-support-salesforce-support-tsx": () => import("./../../../src/templates/landings/salesforce-support/salesforce-support.tsx" /* webpackChunkName: "component---src-templates-landings-salesforce-support-salesforce-support-tsx" */),
  "component---src-templates-landings-sap-commerce-sap-commerce-tsx": () => import("./../../../src/templates/landings/sap-commerce/sap-commerce.tsx" /* webpackChunkName: "component---src-templates-landings-sap-commerce-sap-commerce-tsx" */),
  "component---src-templates-landings-sd-inventory-management-sd-inventory-management-tsx": () => import("./../../../src/templates/landings/sd-inventory-management/sd-inventory-management.tsx" /* webpackChunkName: "component---src-templates-landings-sd-inventory-management-sd-inventory-management-tsx" */),
  "component---src-templates-landings-sd-order-management-sd-order-management-tsx": () => import("./../../../src/templates/landings/sd-order-management/sd-order-management.tsx" /* webpackChunkName: "component---src-templates-landings-sd-order-management-sd-order-management-tsx" */),
  "component---src-templates-landings-security-management-security-management-tsx": () => import("./../../../src/templates/landings/security-management/security-management.tsx" /* webpackChunkName: "component---src-templates-landings-security-management-security-management-tsx" */),
  "component---src-templates-landings-services-services-tsx": () => import("./../../../src/templates/landings/services/services.tsx" /* webpackChunkName: "component---src-templates-landings-services-services-tsx" */),
  "component---src-templates-landings-sharepoint-consulting-sharepoint-consulting-tsx": () => import("./../../../src/templates/landings/sharepoint-consulting/sharepoint-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-sharepoint-consulting-sharepoint-consulting-tsx" */),
  "component---src-templates-landings-smart-contract-smart-contract-tsx": () => import("./../../../src/templates/landings/smart-contract/smart-contract.tsx" /* webpackChunkName: "component---src-templates-landings-smart-contract-smart-contract-tsx" */),
  "component---src-templates-landings-smart-teams-smart-teams-tsx": () => import("./../../../src/templates/landings/smart-teams/smart-teams.tsx" /* webpackChunkName: "component---src-templates-landings-smart-teams-smart-teams-tsx" */),
  "component---src-templates-landings-software-development-accounting-software-development-accounting-tsx": () => import("./../../../src/templates/landings/software-development-accounting/software-development-accounting.tsx" /* webpackChunkName: "component---src-templates-landings-software-development-accounting-software-development-accounting-tsx" */),
  "component---src-templates-landings-software-development-invoicing-software-development-invoicing-tsx": () => import("./../../../src/templates/landings/software-development-invoicing/software-development-invoicing.tsx" /* webpackChunkName: "component---src-templates-landings-software-development-invoicing-software-development-invoicing-tsx" */),
  "component---src-templates-landings-software-development-product-software-development-product-tsx": () => import("./../../../src/templates/landings/software-development-product/software-development-product.tsx" /* webpackChunkName: "component---src-templates-landings-software-development-product-software-development-product-tsx" */),
  "component---src-templates-landings-software-hi-tech-software-hi-tech-tsx": () => import("./../../../src/templates/landings/software-hi-tech/software-hi-tech.tsx" /* webpackChunkName: "component---src-templates-landings-software-hi-tech-software-hi-tech-tsx" */),
  "component---src-templates-landings-software-security-consulting-software-security-consulting-tsx": () => import("./../../../src/templates/landings/software-security-consulting/software-security-consulting.tsx" /* webpackChunkName: "component---src-templates-landings-software-security-consulting-software-security-consulting-tsx" */),
  "component---src-templates-landings-something-wrong-something-wrong-tsx": () => import("./../../../src/templates/landings/something-wrong/something-wrong.tsx" /* webpackChunkName: "component---src-templates-landings-something-wrong-something-wrong-tsx" */),
  "component---src-templates-landings-stock-trading-software-development-stock-trading-software-development-tsx": () => import("./../../../src/templates/landings/stock-trading-software-development/stock-trading-software-development.tsx" /* webpackChunkName: "component---src-templates-landings-stock-trading-software-development-stock-trading-software-development-tsx" */),
  "component---src-templates-landings-telecommunication-software-telecommunication-software-tsx": () => import("./../../../src/templates/landings/telecommunication-software/telecommunication-software.tsx" /* webpackChunkName: "component---src-templates-landings-telecommunication-software-telecommunication-software-tsx" */),
  "component---src-templates-landings-telehealth-solutions-telehealth-solutions-tsx": () => import("./../../../src/templates/landings/telehealth-solutions/telehealth-solutions.tsx" /* webpackChunkName: "component---src-templates-landings-telehealth-solutions-telehealth-solutions-tsx" */),
  "component---src-templates-landings-thanks-thanks-tsx": () => import("./../../../src/templates/landings/thanks/thanks.tsx" /* webpackChunkName: "component---src-templates-landings-thanks-thanks-tsx" */),
  "component---src-templates-landings-underwriting-software-underwriting-software-tsx": () => import("./../../../src/templates/landings/underwriting-software/underwriting-software.tsx" /* webpackChunkName: "component---src-templates-landings-underwriting-software-underwriting-software-tsx" */),
  "component---src-templates-landings-web-app-dev-web-app-dev-tsx": () => import("./../../../src/templates/landings/web-app-dev/web-app-dev.tsx" /* webpackChunkName: "component---src-templates-landings-web-app-dev-web-app-dev-tsx" */),
  "component---src-templates-landings-web-portal-web-portal-tsx": () => import("./../../../src/templates/landings/web-portal/web-portal.tsx" /* webpackChunkName: "component---src-templates-landings-web-portal-web-portal-tsx" */),
  "component---src-templates-news-news-tsx": () => import("./../../../src/templates/news/news.tsx" /* webpackChunkName: "component---src-templates-news-news-tsx" */),
  "component---src-templates-portfolio-portfolio-tsx": () => import("./../../../src/templates/portfolio/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-portfolio-tsx" */)
}

